const state = {
    showFullNav: true
}

const mutations = {
    toggleMainNav(context, showHide) {
        state.showFullNav = showHide;
    }
}

const actions = {
    toggleMainNav (context, showHide) {
        context.commit('toggleMainNav', showHide);
      }
}

const getters = {
    getShowFullNav: state => state.showFullNav,
}

export default {
  state,
  mutations,
  getters,
  actions
}