<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddTheme' }" tag="button" class="btn" title="Add Destination">
        <i class="fal fa-plus-circle"></i>
        Add Theme
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fal fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Destinations'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="themeList"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'title', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'title'">
                    <span>
                        <a @click="viewTheme(props.row.ref)" href="#" title="View"><strong>{{ props.row.title }}</strong></a>
                    </span>
                  </span>
                  <span v-else>
                      {{props.formattedRow[props.column.field]}}
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Themes',
  data() {
    return {
      title: 'Themes',
      themeList: [],
      tableSearchTerm: '',
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Ref',
          field: 'ref',
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
        }
      ]
    }
  },
  metaInfo: {
    title: 'All Themes'
  },
  components: {
  },

  methods: {
    getThemes: async function() {
      // Get All Themes
      let self = this;
      let themes = await self.db.collection('themes').get();
      self.themeList = themes.docs.map(doc => doc.data());
    },

    viewTheme: function(ref) {
      let self = this;
      
      this.$router.push({
        name: 'EditTheme',
        params: {
          ref: ref
        }
      });
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.getThemes();
    }
  },

  computed: {
    ...mapGetters([
        'db'
    ])
  },

  async mounted() {
    let self = this;
    self.getThemes();
  }
}
</script>
