<template>
  <div class="page-body">

    <div class="page-title-bar">
      <h1>{{ title }}</h1>
    </div>

    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
            <div class="actions">
              <span class="search-icon"><i class="fal fa-search"></i></span>
              <input class="search-input" type="text" :placeholder="'Search Hotel Bookings'" v-model="tableSearchTerm" />
            </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="results"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'createdAt', type: 'desc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

            <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'bookingId'">
                <span>
                    <a @click="viewBookingPage(props.row)" href="#" title="View"><strong>{{ props.row.bookingId }}</strong></a>
                </span>
                </span>
              <span v-else-if="props.column.field === 'status'" :class="props.row.status === 'complete' ? 'completed' : 'failed'">
                <strong>{{ props.row.status }}</strong>
                </span>
              <span v-else-if="props.column.field == 'actions'">
                <span>
                    <button class="btn btn-sm" @click="viewBookingPage(props.row)" title="View">View</button>
                </span>
                </span>
              <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>

          </vue-good-table>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {VueGoodTable} from 'vue-good-table';
import BookingsApi from "@/services/admin/bookingsApi";
import {mapGetters} from 'vuex';

export default {
  name: 'Hotels',
  data() {
    return {
      title: 'Hotel Bookings',
      results: [],
      api: new BookingsApi(),
      tableSearchTerm: '',
      columns: [
        {
          label: 'Booking ID',
          field: 'bookingId',
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'',
          dateOutputFormat: 'MM-dd-Y p',
          tdClass: 'created-col',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Hotel',
          field: 'hotel'
        },
        {
          label: 'Check In',
          field: 'checkIn',
        },
        {
          label: 'Check Out',
          field: 'checkOut',
        },
        {
          label: 'Amount Charged',
          field: 'amount',
          type: 'decimal',
        },
        {
          label: 'Service Fee',
          field: 'metadata.serviceFee',
          type: 'decimal'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Agent',
          field: 'agentName'
        },
        {
          label: '',
          field: 'actions',
          sortable: false
        }
      ]
    }
  },

  components: {
    VueGoodTable
  },

  computed: {
    ...mapGetters([
        'getCurrentWebsite'
    ])
  },

  watch: {
    'getCurrentWebsite': async function () {
      let self = this;
      await self.loadHotelResults();
    }
  },

  methods: {
    async loadHotelResults() {
      let self = this;
      let results = (await self.api.getHotelBookings()).data;
      self.results = results;
    },
    viewBookingPage(row) {
      let bookingPlatformHostName = window.TravelPlatformAdmin.site.bookingPlatformHostName;
      window.open(`https://${bookingPlatformHostName}/en/bookings/hotels/${row.bookingId}/${row.email}`, '_blank');
    }
  },

  async created() {
    let self = this;
    await self.loadHotelResults();
  },

}
</script>
