import Vue from 'vue';
import Vuex from 'vuex';

import general from '@/store/modules/general';
import settings from '@/store/modules/settings';
import users from "@/store/modules/users";
import {vuexfireMutations} from "vuexfire";

Vue.use(Vuex);

export const store = new Vuex.Store ({
  mutations: {
    ...vuexfireMutations
  },
  modules: {
    namespaced: true,
    general,
    settings,
    users,
  }
});