const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class UsersApi {

  async getUsers() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/getUsers`, {}, {headers});
  }

  async getUser(uid) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/getUser`, {uid}, {headers});
  }

  async addNewUser(userDetails) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post('/api/admin/addUser', {user: userDetails}, {headers});
  }

  async updateUser(uid, userDetails) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/users/${uid}`, {user: userDetails}, {headers});
  }

  async deleteUser(uid) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/deleteUser/${uid}`, null, {headers});
  }

}
