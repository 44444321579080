<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <button class="btn" @click="updateSettings()" title="Save Changes">Save Changes</button>
    </div>
    
    <p v-if="loading">Loading</p>

    <div v-if="!loading">
      <ValidationObserver ref="settingsForm">
        <ul class="form-tabs">
          <li 
            v-for="(tab, tabIndex) in formTabs" 
            :key="tabIndex" 
            @click="currentTab = tab"
            :class="{ 'active' : currentTab === tab}"
            >
            <h2>{{tab}}</h2>
          </li>
        </ul>
        <div class="settings-form">
          <div class="content-panel" v-show="currentTab === 'Flights'">

            <div class="display-group long-content">
              <div class="label-description">
                <h3>Confirmation Page</h3>
                <p>Custom content to be displayed at the bottom of the <strong>Flight Confirmation Page</strong></p>
              </div>
              <div class="controls">
                <wysiwyg-editor 
                  v-model="messaging.flights.confirmationPage.content" 
                  class="html-editor"
                  :api-key="tinyApi" 
                  toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                  :init="{}">
                </wysiwyg-editor>
              </div>
            </div>

            <div class="display-group long-content end">
              <div class="label-description">
                <h3>Confirmation Email</h3>
                <p>Custom content to be displayed at the bottom of the <strong>Flight Confirmation Email</strong></p>
              </div>
              <div class="controls">
                <wysiwyg-editor 
                  v-model="messaging.flights.confirmationEmail.content" 
                  class="html-editor"
                  :api-key="tinyApi" 
                  toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                  :init="{}">
                </wysiwyg-editor>
              </div>
            </div>
          </div>

          <div class="content-panel" v-show="currentTab === 'Hotels'">
            <div class="display-group long-content">
              <div class="label-description">
                <h3>Confirmation Page</h3>
                <p>Custom content to be displayed at the bottom of the <strong>Hotel Confirmation Page</strong></p>
              </div>
              <div class="controls">
                <wysiwyg-editor 
                  v-model="messaging.hotels.confirmationPage.content" 
                  class="html-editor"
                  :api-key="tinyApi" 
                  toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                  :init="{}">
                </wysiwyg-editor>
              </div>
            </div>

            <div class="display-group long-content end">
              <div class="label-description">
                <h3>Confirmation Email</h3>
                <p>Custom content to be displayed at the bottom of the <strong>Hotel Confirmation Email</strong></p>
              </div>
              <div class="controls">
                <wysiwyg-editor 
                  v-model="messaging.hotels.confirmationEmail.content" 
                  class="html-editor"
                  :api-key="tinyApi" 
                  toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                  plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                  :init="{}">
                </wysiwyg-editor>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue';
import SiteApi from "@/services/siteApi";

export default {
  name: 'Settings',
  data() {
    return {
      title: 'Customer Messaging',
      siteApi: null,
      messaging: {},
      tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
      loading: false,
      currentTab: 'Flights',
      formTabs: [
        'Flights',
        'Hotels'
      ]
    }
  },
  metaInfo: {
    title: 'Menu Settings'
  },
  computed: {
    ...mapGetters([
        'db',
        'getCurrentWebsite'
    ])
  },

  components: {
    'wysiwyg-editor': Editor,
  },

  methods: {
    getSettings: async function() {
      let self = this;
      self.loading = true;

      let messaging = await self.db.get();
      self.messaging = messaging.data().messaging;

      self.loading = false;
    },

    updateSettings: async function(ref) {
      let self = this;
      let messaging = self.messaging;

      let success = self.$refs.settingsForm.validate();

      if(success) {
        try {
          await self.db.update({ messaging });
          await self.siteApi.clearSiteDataCache(self.getCurrentWebsite);
          self.$notify({
            group: 'saved',
            title: 'Settings Updated',
            type: 'success'
          });
        }
        catch (err) {
          self.$notify({
            group: 'saved',
            title: 'Error Updating Settings',
            type: 'error'
          });
        }
      }
    }

  },

  async mounted() {
    let self = this;
    self.siteApi = new SiteApi();
    self.getSettings();
  },

  watch: {
    'db': function() {
      let self = this;
      self.getSettings();
    }
  }
}
</script>
