<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartdata', 'options', 'styles'],
  mounted () {
    this.renderChart(this.chartdata, this.options, this.styles)
  }
}
</script>