<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }} 
        <span v-if="themeRef">- {{ theme.title }}</span>
      </h1>
      <div class="btn-set">
        <button class="btn" @click="updateTheme(themeRef ? themeRef : theme.ref)">Save Theme</button>
        <router-link :to="{ name: 'Themes' }" title="All Themes" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>
    
    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">
        <ValidationObserver ref="themeForm">
          <div class="theme-form">

            <div class="form-group">
              <label for="themeTitle">Title<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="themeTitle" v-model="theme.title" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="destinationRef">Ref<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="destinationRef" v-model="theme.ref" :disabled="themeRef" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="themeSubtitle">Subtitle<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="themeSubtitle" v-model="theme.subtitle" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="themeSnippet">Snippet</label>
              <textarea type="text" id="themeSnippet" v-model="theme.snippet"></textarea>
            </div>
            
            <div class="form-group">
              <label for="themePageContent">Page Content</label>
              <wysiwyg-editor 
                id="themePageContent"
                v-model="theme.pageContent" 
                class="html-editor"
                :api-key="tinyApi" 
                toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                :init="{}">
              </wysiwyg-editor>
            </div>

            <div class="form-group">
              <label for="themeDestinations">Destinations</label>
              <v-select :reduce="destination => (destination.ref ? destination.ref : destination.name)"
                v-model="theme.destinations"
                :options="destinationList"
                multiple
                label="ref"
                value="ref"
                id="themeDestinations"
                name="Destinations">
              </v-select>
            </div>

            <div class="link-bar" v-if="themeRef">
              <button class="btn btn-text btn-delete" @click="deleteTheme()" title="Delete this Theme">
                <i class="fal fa-trash"></i>
                Delete Theme
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h4>Thumb Image</h4>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="'websites/hotelsfirstcom/themes/' + theme.ref" :oldImgUrl="theme.thumbImg"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h4>Header Image</h4>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getHeaderImgUrl" :parentContext="'websites/hotelsfirstcom/themes/' + theme.ref" :oldImgUrl="theme.headerImg"></file-uploader>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import FileUploader from '@/components/FileUploader';
import {mapGetters} from 'vuex';

export default {
  name: 'Theme',
  data() {
    return {
      themeRef: this.$route.params.ref,
      theme: { },
      destinationList: [],
      tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
      loading: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },
  computed: {
    ...mapGetters([
        'db'
    ]),
    title: function() {
      let self = this;

      if(self.themeRef) {
        return 'Edit Theme'
      } else {
        return 'Add a Theme'
      }
    }
  },

  components: {
    'FileUploader': FileUploader,
    'wysiwyg-editor': Editor,
  },

  methods: {
    getTheme: async function() {
      let self = this;
      self.loading = true;
      let theme = await self.db.collection('themes').doc(self.themeRef).get();
      self.theme = theme.data();
      self.loading = false;
    },

    updateTheme: function(ref) {
      let self = this;
      self.$refs.themeForm.validate().then(success => {
        if(success) {
          if(self.themeRef) {
            self.db.collection('themes').doc(ref).update(self.theme)
            .then(function() {
              self.$notify({
                group: 'saved',
                title: 'Theme Saved',
                type: 'success'
              });
            })
            .catch(function(error) {
              self.$notify({
                group: 'saved',
                title: 'Error Saving Theme',
                type: 'error'
              });
            });
          } else {
            self.db.collection('themes').doc(ref).set(self.theme)
            .then(function() {
              self.$notify({
                group: 'saved',
                title: 'Theme Successfully Created',
                type: 'success'
              });
              self.$router.push({ path: '/themes/' + ref });
            })
            .catch(function(error) {
              self.$notify({
                group: 'saved',
                title: 'Error Creating Theme',
                type: 'error'
              });
            });
          }
        };
      });
    },

    deleteTheme: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this theme?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => { 
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fal fa-spinner-third fa-spin"></i>';
              self.db.collection('themes').doc(self.themeRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Theme Deleted',
                    type: 'error'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Themes'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting Theme - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getDestinations: async function() {
      let self = this;
      let destinations = await self.db.collection('destinations').get();
      self.destinationList = destinations.docs.map(doc => doc.data());
    },

    getThumbImgUrl (url) {
      let self = this;
      self.theme.thumbImg = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.theme.headerImg = url
    },
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Themes' })
    }
  },

  async mounted() {
    let self = this;
    self.getDestinations();

    if(self.themeRef) {
      self.getTheme();
    }
  }
}
</script>
