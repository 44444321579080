var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar sticky"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'AddDestination' },"tag":"button","title":"Add Destination"}},[_c('i',{staticClass:"fal fa-plus-circle"}),_vm._v(" Add Destination ")])],1),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Destinations'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.destinationList,"styleClass":"vgt-table bookings-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'data.label', type: 'asc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'data.label')?_c('span',[_c('span',[_c('a',{attrs:{"title":"View"},on:{"click":function($event){return _vm.viewDestination(props.row.ref)}}},[_c('strong',[_vm._v(_vm._s(props.row.data.label))])])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fal fa-search"})])}]

export { render, staticRenderFns }