const axios = require('axios');

import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class BookingsApi {

  async getFlightBookings() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/bookings/flight`, {}, {headers});
  }
  async getHotelBookings() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/bookings/hotel`, {}, {headers});
  }

  async getFlightBookingCountsToday() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/reports/flights/counts/today`, {}, {headers});
  }
  async getFlightBookingCountsHistorical() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/reports/flights/counts/historical`, {}, {headers});
  }
  async getFlightLastSevenDaysStats() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/reports/flights/stats/lastSevenDays`, {}, {headers});
  }
  async getFlightTopSearches() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/reports/flights/stats/topSearches`, {}, {headers});
  }
}
