<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>
      <button class="btn" @click="updateSettings()" title="Save Changes">Save Changes</button>
    </div>
    
    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">
        <ValidationObserver ref="settingsForm">
          <div class="settings-form">

            <div class="content-panel">
              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Agency Name<span class="req">*</span></label></h3>
                  <p>The display name for your agency.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAgencyName" v-model="settings.agencyName" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Email Address<span class="req">*</span></label></h3>
                  <p>The email address that customer's will receive all emails from.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAgencyName" v-model="settings.emailAddress" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Bcc Email Address</label></h3>
                  <p>Optional email address that will be copied on all customer transactional and support emails.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <input type="text" id="settingsAgencyName" v-model="settings.emailAddressBcc" />
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Phone Number</label></h3>
                  <p>The agency's phone number.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <input type="text" id="settingsAgencyName" v-model="settings.phoneNumber" />
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Address</label></h3>
                  <p>The agency's physical address.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAgencyName" v-model="settings.address" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">City</label></h3>
                  <p>The city the agency is located in.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAgencyName" v-model="settings.city" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Country</label></h3>
                  <p>The country the agency is located in.</p>
                </div>
                <div class="controls">
                  <div class="form-group mb">
                    <label for="settingsCountry">Country<span class="req">*</span></label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select 
                        v-model="settings.country" 
                        @input="resetRegion()" 
                        :options="getCountryList" 
                        label="name" 
                        :reduce="country => country.code" 
                        id="settingsCountry"
                        name="Country">
                      </v-select>
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group hidden-field" v-if="settings.country && regions && regions.length">
                    <label for="settingsRegion">Region<span class="req">*</span></label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select :reduce="region => (region.code ? region.code : region.name)"
                        v-model="settings.region"
                        :options="selectedCountryRegions(settings.country)"
                        label="name"
                        value="name"
                        id="settingsRegion"
                        name="Province/State">
                      </v-select>
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="display-group end">
                <div class="label-description">
                  <h3><label for="settingsAgencyName">Postal/Zip Code</label></h3>
                  <p>The postal or zip code for the agency.</p>
                </div>
                <div class="controls">
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input type="text" id="settingsAgencyName" v-model="settings.postalZipCode" />
                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteApi from "@/services/siteApi";

export default {
  name: 'Settings',
  data() {
    return {
      title: 'General Settings',
      settings: {},
      regions: [],
      loading: false,
      siteApi: null,
    }
  },
  metaInfo: {
    title: 'General Settings'
  },
  computed: {
    ...mapGetters ([
      'getCountryList',
      'db',
      'getCurrentWebsite'
    ])
  },

  components: {
  },

  async created() {
    let self = this;
    self.siteApi = new SiteApi();
    await self.getSettings();
    self.selectedCountryRegions(self.settings.country);
  },

  methods: {
    getSettings: async function() {
      let self = this;
      self.loading = true;
      let settings = await self.db.get();
      self.settings = settings.data().settings;
      self.loading = false;
    },

    updateSettings: async function(ref) {
      let self = this;
      let settings = self.settings;
      let success = await self.$refs.settingsForm.validate();
      if(success) {
        try {
          await self.db.update({ settings });
          await self.siteApi.clearSiteDataCache(self.getCurrentWebsite);
          self.$notify({
            group: 'saved',
            title: 'Settings Updated',
            type: 'success'
          });
        }
        catch (error){
          self.$notify({
            group: 'saved',
            title: 'Error Updating Settings',
            type: 'error'
          });
        }
      }
    },

    selectedCountryRegions: function (code) {
      let self = this;
      var country = self.getCountryList.find((country) => {
        if(code) {
          if (country.code === code.toUpperCase()) {
            return country;
          }
        }
      });
      
      if (country && country.regions) {
        self.regions = country.regions;
        return country.regions;
      }
      if (country && country.regionNames) {
        self.regions = country.regionNames;
        return country.regionNames;
      }
      return null;
    },

    resetRegion: function() {
      let self = this;
      self.regions = [];
      self.settings.region = '';
      self.selectedCountryRegions(self.settings.country);
    }

  },

  watch: {
    'db': function() {
      let self = this;
      self.getSettings();
    }
  },

  
}
</script>
