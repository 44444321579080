import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Pages from '@/views/Pages.vue'
import Page from '@/views/Page.vue'
import Themes from '@/views/Themes.vue'
import Theme from '@/views/Theme.vue'
import Destinations from '@/views/Destinations.vue'
import Destination from '@/views/Destination.vue'
import Hotels from '@/views/Hotels.vue'
import Hotel from '@/views/Hotel.vue'
import Settings from '@/views/Settings.vue'
import SettingsDisplay from '@/views/SettingsDisplay.vue'
import SettingsMenus from '@/views/SettingsMenus.vue'
import SettingsMessaging from '@/views/SettingsMessaging.vue'
import SettingsUsers from '@/views/SettingsUsers.vue'
import SettingsUser from '@/views/SettingsUser.vue'
import ConfigAgencies from '@/views/ConfigAgencies.vue'
import ConfigAgency from '@/views/ConfigAgency.vue'
import ConfigUsers from '@/views/ConfigUsers.vue'
import ConfigUser from '@/views/ConfigUser.vue'
import Profile from '@/views/Profile.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import {auth} from '@/firebaseConfig'
import HotelBookings from "@/views/bookings/Hotels";
import FlightBookings from "@/views/bookings/Flights";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/admin',
    redirect: Dashboard
  },
  {
    path: '/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/pages/:ref',
    name: 'EditPage',
    component: Page
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: Destinations
  },
  {
    path: '/destinations/add',
    name: 'AddDestination',
    component: Destination
  },
  {
    path: '/destinations/:ref',
    name: 'EditDestination',
    component: Destination
  },
  {
    path: '/hotels',
    name: 'Hotels',
    component: Hotels
  },
  {
    path: '/hotels/add',
    name: 'AddHotel',
    component: Hotel
  },
  {
    path: '/hotels/:ref',
    name: 'EditHotel',
    component: Hotel
  },
  {
    path: '/themes',
    name: 'Themes',
    component: Themes
  },
  {
    path: '/themes/add',
    name: 'AddTheme',
    component: Theme
  },
  {
    path: '/themes/:ref',
    name: 'EditTheme',
    component: Theme
  },
  {
    path: '/settings/general',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/settings/display',
    name: 'SettingsDisplay',
    component: SettingsDisplay
  },
  {
    path: '/settings/menus',
    name: 'SettingsMenus',
    component: SettingsMenus
  },
  {
    path: '/settings/messaging',
    name: 'SettingsMessaging',
    component: SettingsMessaging
  },
  {
    path: '/settings/users',
    name: 'SettingsUsers',
    component: SettingsUsers
  },
  {
    path: '/settings/user/add',
    name: 'SettingsAddUser',
    component: SettingsUser
  },
  {
    path: '/settings/user/:ref',
    name: 'SettingsEditUser',
    component: SettingsUser
  },
  {
    path: '/config/agencies',
    name: 'ConfigAgencies',
    component: ConfigAgencies
  },
  {
    path: '/config/agency/add',
    name: 'ConfigAddAgency',
    component: ConfigAgency
  },
  {
    path: '/config/agency/:ref',
    name: 'ConfigEditAgency',
    component: ConfigAgency
  },
  {
    path: '/config/users',
    name: 'ConfigUsers',
    component: ConfigUsers
  },
  {
    path: '/config/user/add',
    name: 'ConfigAddUser',
    component: ConfigUser
  },
  {
    path: '/config/user/:ref',
    name: 'ConfigEditUser',
    component: ConfigUser
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/bookings/hotel',
    name: 'HotelBookings',
    component: HotelBookings
  },
  {
    path: '/bookings/flight',
    name: 'FlightBookings',
    component: FlightBookings
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.name !== 'Login';
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) next({ path: '/login', query: { redirect: to.fullPath } });
  else if (!requiresAuth && currentUser) next('/');
  else next();
});

export default router;
