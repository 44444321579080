import {fs} from '@/firebaseConfig';

const state = {
  currentUser: null,
  db: null,
  currentWebsite: null,
  currentWebsiteData: null
}

const mutations = {
  updateCurrentUser(context, user) {
    state.currentUser = user;
    if (user) {
      state.db = fs.collection('websites').doc(user.claims.website);
      state.currentWebsite = user.claims.website;
    }
    else {
      state.db = null;
    }
  },

  updateCurrentWebsite(context, website) {
    state.currentWebsite = website;
    if (website) {
      state.db = fs.collection('websites').doc(website);
    }
    else {
      state.db = null;
    }
  },

  updateCurrentWebsiteData(context, data) {
    state.currentWebsiteData = data;
  },
}

const getters = {
  getCurrentUser: state => state.currentUser,
  getCurrentWebsite: state => state.currentWebsite,
  getCurrentWebsiteData: state => state.currentWebsiteData,
  getHasWebsite: state => state.currentWebsiteData.hasWebsite,
  db: state => state.db,
  
}

const actions = {

  setCurrentUser (context, user) {
    context.commit('updateCurrentUser', user);
  },
  setCurrentWebsite (context, website) {
    context.commit('updateCurrentWebsite', website);
  },
  setCurrentWebsiteData (context, data) {
    context.commit('updateCurrentWebsiteData', data);
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}