import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'

const config = {
  apiKey: "AIzaSyAvOui5WXHrnzXDrOu28XigebQdefkwL5Q",
  authDomain: "agent-travel-platform.firebaseapp.com",
  databaseURL: "https://agent-travel-platform.firebaseio.com",
  projectId: "agent-travel-platform",
  storageBucket: "agent-travel-platform.appspot.com",
  messagingSenderId: "962734397504",
  appId: "1:962734397504:web:68520b6ee606f6ba",
  measurementId: "G-ZHXGTHJPM3"
};

const firebaseApp = app.initializeApp(config);
export const fireStorage = firebaseApp.storage();
export const analytics = firebaseApp.analytics();
export const auth = firebaseApp.auth();
export const fs = firebaseApp.firestore();
