<template>
  <section class="global-nav" :class="{ 'minified': !getShowFullNav }">
    <div class="logo">
      <img src='../assets/img/logo.png' alt="TotalTravel" v-show="getShowFullNav" />
      <img src='../assets/img/logo-minified.png' alt="TotalTravel" v-show="!getShowFullNav" />
    </div>
    <ul class="nav">
      <li v-for="(item, itemIndex) in navItems" :key="itemIndex">
        
        <!-- Nav Item Without Children -->
        <router-link v-if="!item.children" :to="{ name: item.component }" @click="isOpen = -1">
          <i :class="item.icon"></i>
          <span class="text">{{ item.name }}</span>
        </router-link>

        <!-- Nav Item With Children -->
        <span v-if="item.children">
          <a :title="item.name" class="accordion" :class="{ 'open': isOpen === itemIndex }" @click="toggleSubNav(itemIndex)" v-if="getShowFullNav">
            <i :class="item.icon"></i>
            <span class="text">
              {{ item.name }}
              <i class="fal fa-angle-down angle" v-if="isOpen !== itemIndex"></i>
              <i class="fal fa-angle-up angle" v-if="isOpen === itemIndex"></i>
            </span>
          </a>
          
          <ul class="subnav" v-if="isOpen === itemIndex && getShowFullNav">
            <li v-for="(child, childIndex) in item.children" :key="childIndex">
              <span v-if="child.requiresWebsite && getHasWebsite">
                <router-link :to="{ name: child.component }">{{ child.name }}</router-link>
              </span>

              <span v-if="!child.requiresWebsite">
                <router-link :to="{ name: child.component }">{{ child.name }}</router-link>
              </span>
            </li>
          </ul>
        </span>
      </li>
    </ul>

    <ul class="nav super-users" v-if="isSuperUser">
      <li v-for="(item, suItemIndex) in superUserNavItems" :key="suItemIndex">
        <!-- Nav Item With Children -->
        <span v-if="item.children">
          <a :title="item.name" class="accordion" :class="{ 'open': isOpen === suItemIndex }" @click="toggleSubNav(suItemIndex)" v-if="getShowFullNav">
            <i :class="item.icon"></i>
            <span class="text">
              {{ item.name }}
              <i class="fal fa-angle-down angle" v-if="isOpen !== suItemIndex"></i>
              <i class="fal fa-angle-up angle" v-if="isOpen === suItemIndex"></i>
            </span>
          </a>
          
          <ul class="subnav" v-if="isOpen === suItemIndex && getShowFullNav">
            <li v-for="(child, suChildIndex) in item.children" :key="suChildIndex">
              <router-link :to="{ name: child.component }">{{ child.name }}</router-link>
            </li>
          </ul>
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalNav',
  data() {
    return {
      navItems: [
        {
          name: 'Dashboard',
          component: 'Dashboard',
          icon: 'fal fa-analytics'
        },
        {
          name: 'Website',
          icon: 'fal fa-sitemap',
          children: [
            {
              name: 'Pages',
              component: 'Pages',
            },
            {
              name: 'Destinations',
              component: 'Destinations',
            },
            {
              name: 'Hotels',
              component: 'Hotels',
              requiresWebsite: true
            },
            {
              name: 'Themes',
              component: 'Themes',
              requiresWebsite: true
            },
          ]
        },
        {
          name: 'Bookings',
          icon: 'fal fa-calendar-alt',
          children: [
            {
              name: 'Flights',
              component: 'FlightBookings'
            },
            {
              name: 'Hotels',
              component: 'HotelBookings'
            }
          ]
        },
        {
          name: 'Settings',
          icon: 'fal fa-cogs',
          children: [
            {
              name: 'Agency Information',
              component: 'Settings'
            },
            {
              name: 'Display Settings',
              component: 'SettingsDisplay'
            },
            {
              name: 'Platform Menus',
              component: 'SettingsMenus'
            },
            {
              name: 'User Management',
              component: 'SettingsUsers'
            },
            {
              name: 'Messaging',
              component: 'SettingsMessaging'
            }
          ]
        },
      ],
      superUserNavItems: [
         {
          name: 'Configuration',
          icon: 'fal fa-user-crown',
          children: [
            {
              name: 'Agencies',
              component: 'ConfigAgencies',
            },
            {
              name: 'Users',
              component: 'ConfigUsers',
            }
          ]
        },
      ],
      isOpen: -1,
      showFullNav: true
    }
  },

  methods: {
    toggleSubNav: function(index) {
      if(this.isOpen !== index) {
        this.isOpen = index;
      } else {
        this.isOpen = -1
      }
    }
  },

  watch: {

  },

  computed: {
    ...mapGetters ([ 
      'getShowFullNav',
      'getCurrentUser',
      'getHasWebsite'
    ]),

    isSuperUser: function() {
      let self = this;
      if(self.getCurrentUser.claims.role === 'su') {
        return true;
      } else { return false }
    }
  }
}
</script>