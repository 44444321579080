<template>
  <div id="app" class="global-container">

    <notifications group="saved" position="center top" />
    <v-dialog />
    <global-nav v-if="getCurrentUser" />

    <div class="body-container">
        <profile-bar v-if="getCurrentUser" />
        <router-view />
    </div>

  </div>
</template>


<script>
import {auth} from "@/firebaseConfig";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
    }
  },
  metaInfo: {
    title: 'Total Travel Admin Platform',
  },
  computed: {
    ...mapGetters([
        'getCurrentUser'
    ])
  }
}
</script>