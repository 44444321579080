<template>
  <div class="page-body">

    <div class="page-title-bar">
      <h1>
        {{ title }} 
        <span v-if="userRef">- {{ user.displayName }}</span>
      </h1>
      <div class="btn-set">
        <button class="btn" @click="updateUser(user)">Save User</button>
        <router-link :to="{ name: 'ConfigUsers' }" title="All Users" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          All Users
        </router-link>
      </div>
    </div>


    <ValidationObserver ref="settingsForm">
      <div class="settings-form">
        <div class="form-group">
          <label for="userDisplayName">Display Name<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="userDisplayName" v-model="user.displayName" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="userEmailAddress">Email Address<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="userEmailAddress" v-model="user.email" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="password">Password<span v-if="!userRef" class="req">*</span></label>
          <validation-provider :rules="`${!userRef ? 'required' : ''}|password:@confirm`" v-slot="{ errors }">
            <input type="password" id="password" v-model="user.password" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm Password<span v-if="!userRef" class="req">*</span></label>
          <validation-provider name="confirm" :rules="`${!userRef ? 'required' : ''}`" v-slot="{ errors }">
            <input type="password" id="confirmPassword" v-model="user.confirmPassword" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>


        <div class="form-group">
          <label for="Website">Website<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-select
                v-model="user.website"
                :options="sites"
                id="Website"
                name="Websites">
            </v-select>
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="form-group">
          <label for="Agency">Agency<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-select
                v-model="user.agency"
                :options="agencies"
                id="Agency"
                name="Websites">
            </v-select>
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="Role">Role<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-select 
              v-model="user.role" 
              :options="['su', 'admin','agent']"
              label="name"
              id="Role"
              name="Role">
            </v-select>
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="link-bar" v-if="userRef">
          <button class="btn btn-text btn-delete" @click="deleteUser(user)" title="Delete User">
            <i class="fal fa-trash"></i>
            Delete User
          </button>
        </div>

      </div>
    </ValidationObserver>


  </div>
</template>

<script>

import UsersApi from "@/services/config/usersApi";
import {fs} from "@/firebaseConfig";

export default {
  name: 'ConfigUser',
  data() {
    return {
      api: new UsersApi(),
      loading: false,
      user: {
        displayName: '',
        email: '',
        role: '',
        enabled: true,
        password: '',
        confirmPassword: '',
        website: '',
        agency: ''
      },
      agencies: [],
      sites: [],
      userRef: this.$route.params.ref,
    }
  },

  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  methods: {
    updateUser: async function (updatedSettings) {
      let self = this;
      let userKey = self.userRef;

      self.$refs.settingsForm.validate().then(async (success) => {
        if(success) {
          if (updatedSettings.password === updatedSettings.confirmPassword) {
            if (userKey) {
              await self.api.updateUser(userKey, self.user);
              this.$notify({
                group: 'saved',
                title: 'Changes Saved',
                type: 'success'
              });
            }
            else {
              await self.api.addNewUser(self.user);
              this.$notify({
                group: 'saved',
                title: 'User Successfully Created',
                type: 'success'
              });
              await self.$router.push('/config/users');
            }

            self.showErrors = false;
          }
          else {
            self.showErrors = true;
          }
        }
      });
    },
    deleteUser: async function () {
      let self = this;
      let userKey = self.userRef;

      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this user?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => { 
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fal fa-spinner-third fa-spin"></i>';
              
              await self.api.deleteUser(userKey);
              self.$notify({
                group: 'saved',
                title: 'User Deleted',
                type: 'success'
              });
              self.showErrors = false;
              self.$modal.hide('dialog');
              self.$router.push({ name: 'ConfigUsers'} )
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    }

  },

  computed: {
    title: function () {
      let self = this;
      if (self.userRef) {
        return 'Edit User'
      } else {
        return 'Add a User'
      }
    }
  },

  async created() {
    let self = this;
    self.sites = (await fs.collection('websites').get()).docs.map(site => site.id);
    self.agencies = (await fs.collection('agencies').get()).docs.map(agency => agency.id);
    if (self.userRef) {
      let user = (await self.api.getUser(self.userRef)).data;
      self.user = {
        displayName: user.displayName,
        email: user.email,
        enabled: !user.disabled,
        password: '',
        confirmPassword: ''
      }
      if (user.customClaims) {
        self.user.role = user.customClaims.role;
        self.user.website = user.customClaims.website;
        self.user.agency = user.customClaims.agency;
      }
    }
  }
}
</script>
