import axios from "axios";
import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class SiteApi {
  async getSite(siteId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/getSite`, {siteId}, {headers});
  }

  async logOut() {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post('/api/logout', null, {headers});
  }

  async clearSiteDataCache(siteId) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/site/clearSiteDataCache`, {siteId}, {headers});
  }

  async clearCache(siteId, requestType, itemToClear) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post(`/api/admin/site/clearCache`, {siteId, requestType, itemToClear}, {headers});
  }
}