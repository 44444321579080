<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
      </h1>
      <div class="btn-set">
        <button class="btn" @click="saveAgency(agency)" title="Save Agency">Save Agency</button>
        <router-link :to="{ name: 'ConfigAgencies' }" title="All Agencies" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          All Agencies
        </router-link>
      </div>
    </div>

    <ValidationObserver ref="settingsForm">
      <div class="settings-form">
        <div class="form-group">
          <label for="userDisplayName">Agency HostName<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="agencyHostName" placeholder="ex: booking.totaltravel.io" :disabled="agencyRef" v-model="agency.agencyHostName" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="userEmailAddress">Website HostName<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="websiteHostName" placeholder="ex: totaltravel.io" :disabled="agencyRef" v-model="agency.websiteHostName" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="json">Website Data<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <json-editor v-model="agency.data" :show-btns="false" :expandedOnStart="true"></json-editor>
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { fs } from '@/firebaseConfig';
import vueJsonEditor from 'vue-json-editor'
import UsersApi from "@/services/admin/usersApi";

export default {
  name: 'ConfigAgency',
  data() {
    return {
      api: new UsersApi(),
      loading: false,
      agency: {
        agencyHostName: '',
        websiteHostName: '',
        data: {
          "display": {
              "faviconUrl": "",
              "currency": "GBP",
              "language": "en",
              "logoUrl": "",
              "products": {
                  "flights": {
                      "sortOrder": "cheapest",
                      "show": true
                  },
                  "hotels": {
                      "show": true,
                      "sortOrder": "lp"
                  }
              },
              "customStyles": ".dsadas {\n font-size: 12px;\n}"
          },
          "bookingPlatformHostName": '',
          "settings": {
              "country": "US",
              "region": "AZ",
              "postalZipCode": "H0H0H0",
              "emailAddress": "contact@hotelsfirst.com",
              "agencyName": "HotelsFirst",
              "address": "123 Front Street"
          },
          "messaging": {
              "flights": {
                  "confirmationEmail": {
                      "title": "Confirmation Email",
                      "content": "Confirmation Email Content Goes Here",
                      "ref": "confirmationEmail"
                  },
                  "confirmationPage": {
                      "content": "Confirmation Page Content Goes Here",
                      "ref": "confirmationPage",
                      "title": "Confirmation Page"
                  }
              },
              "hotels": {
                  "confirmationEmail": {
                      "title": "Confirmation Email",
                      "content": "Confirmation Email Content Goes Here",
                      "ref": "confirmationEmail"
                  },
                  "confirmationPage": {
                      "content": "Confirmation Page Content Goes Here",
                      "tabName": "confirmationPage",
                      "ref": "Confirmation Page"
                  }
              }
          },
          "menus": {
            "bookingPlatform": {
                "footerMenu": [
                    {
                        "title": "Terms",
                        "link": ""
                    },
                    {
                        "title": "Privacy Policy",
                        "link": ""
                    },
                    {
                        "title": "Customer Support",
                        "link": "support"                
                    }
                ],
                "headerMenu": [
                    {
                        "title": "Hotels",
                        "link": ""
                    },
                    {
                        "title": "Flights",
                        "link": ""
                    },
                    {
                        "title": "Support",
                        "link": "support"
                        
                    }
                ],
                "logoLink": {
                    "title": "YourDomain.com",
                    "link": ""
                }
            },
            "websitePlatform": {
                "footerMenu": [
                    {
                        "title": "Terms",
                        "link": ""
                    },
                    {
                        "title": "Privacy Policy",
                        "link": ""
                    },
                    {
                        "title": "Customer Support",
                        "link": "support"                
                    }
                ],
                "headerMenu": [
                    {
                        "title": "Hotels",
                        "link": ""
                    },
                    {
                        "title": "Flights",
                        "link": ""
                    },
                    {
                        "title": "Support",
                        "link": "support"
                        
                    }
                ],
                "logoLink": {
                    "title": "YourDomain.com",
                    "link": ""
                }
            }
        }
        }
      },
      agencyRef: this.$route.params.ref,
    }
  },

  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  components: {
    'json-editor': vueJsonEditor,
  },

  methods: {
    getAgency: async function() {
      let self = this;
      self.loading = true;
      let agency = await fs.collection('websites').doc(self.agencyRef).get();
      self.agency.data = agency.data();
      self.agency.websiteHostName = agency.id;
      self.loading = false;
    },

    saveAgency: function() {
      let self = this;
      let agencyHostNameSplit = self.agency.agencyHostName.split('.').join("");
      let websiteHostNameSplit = self.agency.websiteHostName.split('.').join("");
      self.agency.data.bookingPlatformHostName = self.agency.agencyHostName;

      if(self.agencyRef) {
        fs.collection('websites').doc(self.agencyRef).update(self.agency.data)
        .then(function(wref) {
          self.$notify({
            group: 'saved',
            title: 'Agency Saved',
            type: 'success'
          });
        })
        .catch(function(error) {
          self.$notify({
            group: 'saved',
            title: 'Error Saving:' + error,
            type: 'error'
          });
        });
      } else {
        fs.collection('agencies').doc(agencyHostNameSplit).set({ 'websiteHostName': self.agency.websiteHostName })
        .then(function(ref) {
          fs.collection('websites').doc(websiteHostNameSplit).set(self.agency.data)
          .then(function(wref) {
            self.$notify({
              group: 'saved',
              title: 'Website & Agency Created',
              type: 'success'
            });

            setTimeout(() => {
              self.$router.push({ name: 'ConfigAgencies' })
            }, 2000);
          })
          .catch(function(error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Website:' + error,
              type: 'error'
            });
        });
        })
        .catch(function(error) {
            self.$notify({
              group: 'saved',
              title: 'Error Creating Agency:' + error,
              type: 'error'
            });
        });
      }
      

    },

    highlighter: function(code) {
      return highlight(code, languages.js);
    },
  },

  computed: {
    title: function () {
      let self = this;
      if (self.agencyRef) {
        return 'Edit Agency'
      } else {
        return 'Add an Agency'
      }
    }
  },

  async created() {
    let self = this;
    if(self.agencyRef) {
      await self.getAgency();
      self.agency.agencyHostName = self.agency.data.bookingPlatformHostName;
    }
  }
}
</script>
