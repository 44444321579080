<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }} 
        <span v-if="userRef">- {{ user.displayName }}</span>
      </h1>
      <div class="btn-set">
        <button class="btn" @click="updateUser(user)">Save User</button>
        <router-link :to="{ name: 'SettingsUsers' }" title="All Users" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          All Users
        </router-link>
      </div>
    </div>


    <ValidationObserver ref="settingsForm">
      <div class="settings-form">
        <div class="form-group">
          <label for="userDisplayName">Display Name<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="userDisplayName" v-model="user.displayName" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="userEmailAddress">Email Address<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="userEmailAddress" v-model="user.email" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="password">Password<span v-if="!userRef" class="req">*</span></label>
          <validation-provider :rules="`${!userRef ? 'required' : ''}|password:@confirm`" v-slot="{ errors }">
            <input type="password" id="password" v-model="user.password" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm Password<span v-if="!userRef" class="req">*</span></label>
          <validation-provider name="confirm" :rules="`${!userRef ? 'required' : ''}`" v-slot="{ errors }">
            <input type="password" id="confirmPassword" v-model="user.confirmPassword" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>


        <div class="form-group">
          <label for="Role">Role<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-select 
              v-model="user.role" 
              :options="['admin','agent']"
              label="name"
              id="Role"
              name="Role">
            </v-select>
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="link-bar" v-if="userRef">
          <button class="btn btn-text btn-delete" @click="deleteUser()" title="Delete User">
            <i class="fal fa-trash"></i>
            Delete User
          </button>
        </div>
      </div>
    </ValidationObserver>


  </div>
</template>

<script>

import UsersApi from "@/services/admin/usersApi";

export default {
  name: 'SettingsUser',
  data() {
    return {
      api: new UsersApi(),
      loading: false,
      user: {
        displayName: '',
        email: '',
        role: '',
        enabled: true,
        password: '',
        confirmPassword: ''
      },
      userRef: this.$route.params.ref,
    }
  },

  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  async created() {
    let self = this;
    if (self.userRef) {
      let user = (await self.api.getUser(self.userRef)).data;
      self.user = {
        displayName: user.displayName,
        email: user.email,
        role: user.customClaims ? user.customClaims.role : '',
        enabled: !user.disabled,
        password: '',
        confirmPassword: ''
      }
    }
  },

  methods: {
    deleteUser: async function () {
      let self = this;
      let userKey = self.userRef;

      await self.api.deleteUser(userKey);

      self.showErrors = false;

      this.$notify({
        group: 'saved',
        title: 'User Deleted',
        type: 'success'
      })

      await self.$router.push('/settings/users');

    },
    updateUser: async function (updatedSettings) {
      let self = this;
      let userKey = self.userRef;

      self.$refs.settingsForm.validate().then(async (success) => {
        if(success) {
          if (updatedSettings.password === updatedSettings.confirmPassword) {
            if (userKey) {
              await self.api.updateUser(userKey, self.user);
            }
            else {
              await self.api.addNewUser(self.user);
            }

            self.showErrors = false;

            this.$notify({
              group: 'saved',
              title: 'Changes Saved',
              type: 'success'
            });

            if (!userKey) {
              await self.$router.push('/settings/users');
            }
          }
          else {
            self.showErrors = true;
          }
        }
      });
    }
  },

  computed: {
    title: function () {
      let self = this;
      if (self.userRef) {
        return 'Edit User'
      } else {
        return 'Add a User'
      }
    }
  },
}
</script>
