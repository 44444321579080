<template>
  <div class="page-body">

    <div class="page-title-bar">
      <h1>
        {{ title }} 
        <span v-if="page">- {{ page.pageTitle }}</span>
      </h1>
      <div class="btn-set">
        <button class="btn" @click="updatePage()">Save Page</button>
        <router-link :to="{ name: 'Pages' }" title="All Pages" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>
    
    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="pageForm">
          <div class="page-form">

            <div class="form-group">
              <label for="themeTitle">Title<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="themeTitle" v-model="page.pageTitle" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="themeRef">Ref<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="themeRef" disabled v-model="page.ref" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <!-- <div class="form-group">
              <label for="pageDescription">Description</label>
              <textarea type="text" id="pageDescription" v-model="page.pageDescription" />
            </div> -->


            <div v-if="pageRef === 'bookinghome'" class="form-group">
              <label for="pageDestinations">Destinations</label>
              <v-select :reduce="destination => (destination.ref ? destination.ref : destination.data.label)"
                v-model="page.destinations"
                :options="destinationList"
                multiple
                label="ref"
                value="ref"
                id="pageDestinations"
                name="Destinations">
              </v-select>
            </div>

            <div v-if="pageRef === 'bookinghome'" class="form-table-group">
              <h3>Flight Offers</h3>

              <table cellpadding="0" cellspacing="0" border="0" class="form-table">
                <thead>
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Round Trip</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(offer, offerIndex) in page.flightOffers" :key="offerIndex">
                    <td>{{ offer.from.label }} ({{ offer.from.value }})</td>
                    <td>{{ offer.to.label }} ({{ offer.to.value }})</td>
                    <td><i v-if="offer.roundTrip" class="fal fa-check"></i></td>
                    <td><i class="fal fa-times delete" @click="deleteFlightOffer(offerIndex)"></i></td>
                  </tr>
                </tbody>
              </table>

              <div class="new-offer-form">
                <h4>Add a New Flight Offer</h4>
                <div class="flex">
                  <v-select
                    v-model="newFlightOffer.from"
                    @search="searchDestinationLocation"
                    :options="airportResults"
                    :placeholder="'Departure Airport'"
                    label="label"
                    :filterable="false"
                    >
                    <template slot="no-options">
                        Departure Airport...
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="autocomplete-result">
                            <i class="fal fa-plane"></i>
                            {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                        </div>
                    </template>
                  </v-select>

                  <v-select
                    v-model="newFlightOffer.to"
                    @search="searchDestinationLocation"
                    :options="airportResults"
                    :placeholder="'Arrival Airport'"
                    label="label"
                    :filterable="false"
                    >
                    <template slot="no-options">
                        Arrival Airport...
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="autocomplete-result">
                            <i class="fal fa-plane"></i>
                            {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                        </div>
                    </template>
                  </v-select>

                  <div class="checkbox-group">
                    <label for="roundTripCheck">
                      <input type="checkbox" id="roundTripCheck" v-model="newFlightOffer.roundTrip" />
                      Round Trip
                    </label>
                  </div>
                </div>

                <button class="btn btn-sm" @click="addNewFlightOffer()" title="Add Flight Offer">Add Flight Offer</button>
              </div>
            </div>

            <div class="form-group">
              <label for="pageContent">Page Content</label>
              <wysiwyg-editor 
                id="pageContent"
                v-model="page.pageContent" 
                class="html-editor"
                :api-key="tinyApi" 
                toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                :init="{}">
              </wysiwyg-editor>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Header Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getHeaderImgUrl" :parentContext="'websites/' + getCurrentWebsite + '/pages/' + page.ref + '/'" :oldImgUrl="page.headerImg"></file-uploader>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import FileUploader from '@/components/FileUploader'
import {mapGetters} from 'vuex';
import HotelsApi from "@/services/admin/hotelsApi";
import SiteApi from "@/services/siteApi";

export default {
  name: 'Page',
  data() {
    return {
      hotelsApi: null,
      siteApi: null,
      pageRef: this.$route.params.ref,
      page: {},
      tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
      loading: false,
      componentList: [],
      destinationList: [],
      destinationResults: [],
      airportResults: [],
      newFlightOffer: {}
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },
  computed: {
    ...mapGetters([
        'db',
        'getCurrentWebsite'
    ]),
    title: function() {
      let self = this;

      if(self.pageRef) {
        return 'Edit Page'
      } else {
        return 'Add a Page'
      }
    }
  },

  components: {
    'FileUploader': FileUploader,
    'wysiwyg-editor': Editor,
  },

  methods: {
    getPage: async function() {
      let self = this;
      self.loading = true;
      let page = await self.db.collection('pages').doc(self.pageRef).get();
      self.page = page.data();
      if(!self.page.flightOffers) {
        self.page.flightOffers = []
      }
      self.loading = false;
    },

    updatePage: async function(ref) {
      let self = this;
      let success = await self.$refs.pageForm.validate();

      if(success) {
        try {
          self.page.flightOffers.forEach((offer) => {
            if(!offer.to.state) { offer.to.state = ''}
            if(!offer.from.state) { offer.from.state = ''}
          })
          await self.db.collection('pages').doc(self.pageRef).update(self.page);
          await self.siteApi.clearCache(self.getCurrentWebsite, 'page', self.pageRef);

          self.$notify({
            group: 'saved',
            title: 'Page Saved',
            type: 'success'
          });
        }
        catch (error) {
          self.$notify({
            group: 'saved',
            title: 'Error Saving Page',
            type: 'error'
          });
        }
      }
    },

    getDestinations: async function() {
      let self = this;
      let destinations = await self.db.collection('destinations').get();
      self.destinationList = destinations.docs.map(doc => doc.data());
    },

    searchDestinationLocation: function(search, loading) {
      let self = this;
      if (self.timeout) clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {

          if(search != '') {
              loading(true);
              self.searchForDestinationUsingApi(search)
                .then(function (results) {
                  results = results.getHotelAutoSuggestV2.results.result;
                  self.destinationResults = [];
                  if (results.cities) {
                    self.destinationResults = self.destinationResults.concat(results.cities.map(item => {
                      return {rank: item.rank, label: item.city, nearbyAirports: item.nearby_airports_json, value: item.cityid_ppn, state: item.state_code, country: item.country_code, type: item.type};
                    }));
                  }
                  if (results.airports) {
                    self.destinationResults = self.destinationResults.concat(results.airports.map(item => {
                      return {rank: item.rank, label: item.airport, value: item.iata, state: item.state_code, country: item.country_code, type: item.type}
                    }));

                    self.airportResults = results.airports.map(item => {
                      return {rank: item.rank, label: item.airport, value: item.iata, state: item.state_code, country: item.country_code, type: item.type}
                    });
                  }
                  if (results.hotels) {
                    self.destinationResults = self.destinationResults.concat(results.hotels.map(item => {
                      return {rank: item.rank, label: item.hotel_name, value: item.hotelid_ppn, state: item.address.state_code, country: item.address.country_code, type: item.type}
                    }));
                  }
                  loading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
    }, 500);
    },

    searchForDestinationUsingApi: function(search) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.hotelsApi.suggest(search)
            .then(results => { resolve(results.data); })
            .catch(ex => { reject(ex); });
        });
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.page.headerImg = url
    },

    addNewFlightOffer: function() {
      let self = this;
      self.page.flightOffers.push(self.newFlightOffer);
      self.newFlightOffer = {};
    },

    deleteFlightOffer: function(index) {
        let self = this;
        if (confirm('Are you sure you want to delete?')) {
            self.page.flightOffers.splice(index, 1)
        }
    },
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Pages' })
    }
  },

  async mounted() {
    let self = this;
    self.siteApi = new SiteApi();
    self.hotelsApi = new HotelsApi();
    await self.getDestinations();
    if(self.pageRef) {
      self.getPage();
    }
  }
}
</script>
