var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"content-panel"},[_c('div',{staticClass:"panel-body no-title"},[_c('div',{staticClass:"data-table"},[_c('div',{staticClass:"data-table-search"},[_c('div',{staticClass:"actions"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSearchTerm),expression:"tableSearchTerm"}],staticClass:"search-input",attrs:{"type":"text","placeholder":'Search Flight Bookings'},domProps:{"value":(_vm.tableSearchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tableSearchTerm=$event.target.value}}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.results,"styleClass":"vgt-table bookings-table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'createdAt', type: 'desc'}
            },"search-options":{
                enabled: true,
                skipDiacritics: true,
                externalQuery: _vm.tableSearchTerm
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'id')?_c('span',[_c('span',[_c('a',{attrs:{"href":"#","title":"View"},on:{"click":function($event){return _vm.viewBookingPage(props.row)}}},[_c('strong',[_vm._v(_vm._s(props.row.id))])])])]):(props.column.field === 'status')?_c('span',{class:props.row.status === 'complete' ? 'completed' : 'failed'},[_c('strong',[_vm._v(_vm._s(props.row.status))])]):(props.column.field == 'actions' && props.row.status === 'ACTIVE')?_c('span',[_c('span',[_c('button',{staticClass:"btn btn-sm",attrs:{"title":"View"},on:{"click":function($event){return _vm.viewBookingPage(props.row)}}},[_vm._v("View")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fal fa-search"})])}]

export { render, staticRenderFns }