var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-title-bar"},[_c('h1',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.userRef)?_c('span',[_vm._v("- "+_vm._s(_vm.user.displayName))]):_vm._e()]),_c('div',{staticClass:"btn-set"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.updateUser(_vm.user)}}},[_vm._v("Save User")]),_c('router-link',{staticClass:"btn btn-sec",attrs:{"to":{ name: 'ConfigUsers' },"title":"All Users","tag":"button"}},[_c('i',{staticClass:"fal fa-angle-left"}),_vm._v(" All Users ")])],1)]),_c('ValidationObserver',{ref:"settingsForm"},[_c('div',{staticClass:"settings-form"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userDisplayName"}},[_vm._v("Display Name"),_c('span',{staticClass:"req"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.displayName),expression:"user.displayName"}],attrs:{"type":"text","id":"userDisplayName"},domProps:{"value":(_vm.user.displayName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "displayName", $event.target.value)}}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userEmailAddress"}},[_vm._v("Email Address"),_c('span',{staticClass:"req"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],attrs:{"type":"text","id":"userEmailAddress"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password"),(!_vm.userRef)?_c('span',{staticClass:"req"},[_vm._v("*")]):_vm._e()]),_c('validation-provider',{attrs:{"rules":((!_vm.userRef ? 'required' : '') + "|password:@confirm")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password"),(!_vm.userRef)?_c('span',{staticClass:"req"},[_vm._v("*")]):_vm._e()]),_c('validation-provider',{attrs:{"name":"confirm","rules":("" + (!_vm.userRef ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.confirmPassword),expression:"user.confirmPassword"}],attrs:{"type":"password","id":"confirmPassword"},domProps:{"value":(_vm.user.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "confirmPassword", $event.target.value)}}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"Website"}},[_vm._v("Website"),_c('span',{staticClass:"req"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.sites,"id":"Website","name":"Websites"},model:{value:(_vm.user.website),callback:function ($$v) {_vm.$set(_vm.user, "website", $$v)},expression:"user.website"}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"Agency"}},[_vm._v("Agency"),_c('span',{staticClass:"req"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.agencies,"id":"Agency","name":"Websites"},model:{value:(_vm.user.agency),callback:function ($$v) {_vm.$set(_vm.user, "agency", $$v)},expression:"user.agency"}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"Role"}},[_vm._v("Role"),_c('span',{staticClass:"req"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":['su', 'admin','agent'],"label":"name","id":"Role","name":"Role"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('span',{staticClass:"invalid"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.userRef)?_c('div',{staticClass:"link-bar"},[_c('button',{staticClass:"btn btn-text btn-delete",attrs:{"title":"Delete User"},on:{"click":function($event){return _vm.deleteUser(_vm.user)}}},[_c('i',{staticClass:"fal fa-trash"}),_vm._v(" Delete User ")])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }