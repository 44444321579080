import axios from "axios";
import AuthHelper from "@/services/admin/authHelper";
const authHelper = new AuthHelper();

export default class HotelsApi {
  async suggest(destination) {
    let headers = await authHelper.getAuthHeaders();
    return await axios.post('/api/hotels/suggest', {
      destination: destination,
    }, {headers});
  }
}