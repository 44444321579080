<template>
  <div class="page-body">
    <div class="page-title-bar">
      <h1>{{title}}</h1>
      <button class="btn" @click="updateUser()">Save Changes</button>
    </div>

    <ValidationObserver ref="settingsForm">
      <div class="settings-form">
        <div class="form-group">
          <label for="">Email Address<span class="req">*</span></label>
          <p>{{ user.email }}</p>
          <p class="description">Please contact a Platform Admin to change your account email address.</p>
        </div>

        <div class="form-group">
          <label for="userDisplayName">Display Name<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="userDisplayName" v-model="user.displayName" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="form-group">
          <label for="password">Password<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="password" v-model="user.password" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm Password<span class="req">*</span></label>
          <validation-provider rules="required" v-slot="{ errors }">
            <input type="text" id="confirmPassword" v-model="user.confirmPassword" />
            <span class="invalid">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>

import {auth} from "@/firebaseConfig";
import UsersApi from "@/services/admin/usersApi";
import {store} from "@/store/store";

export default {
  name: 'Profile',
  data() {
    return {
      title: 'My Profile',
      api: new UsersApi(),
      user: {
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    }
  },
  metaInfo: {
    title: 'User Profile'
  },
  async created() {
    let self = this;
    let user = (await self.api.getUser(auth.currentUser.uid)).data;
    self.user = {
      displayName: user.displayName,
      email: user.email,
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    async updateUser() {
      let self = this;

      let currentUser = auth.currentUser;

      // TODO: Rob - define photo uploader

      await currentUser.updateProfile({displayName: self.user.displayName, photoURL: ""});


      if (self.user.password === self.user.confirmPassword && self.user.password !== '') {
        await currentUser.updatePassword(self.user.password);
      }

      let tokenResult = await currentUser.getIdTokenResult(true);
      await store.dispatch('setCurrentUser', tokenResult);

      this.$notify({
        group: 'saved',
        title: 'Changes Saved',
        type: 'success'
      });
    }
  }
}
</script>
