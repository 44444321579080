<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'SettingsAddUser' }" tag="button" class="btn" title="Add a User">
        <i class="fal fa-plus-circle"></i>
        Add a User
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
              <div class="actions">
                <span class="search-icon"><i class="fal fa-search"></i></span>
                <input class="search-input" type="text" :placeholder="'Search Users'" v-model="tableSearchTerm" />
              </div>
          </div>

          <vue-good-table
              :columns="columns"
              :rows="userList"
              styleClass="vgt-table bookings-table"
              :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'displayName', type: 'asc'}
              }"

              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  externalQuery: tableSearchTerm
              }"

              :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 50,
                  position: 'bottom',
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page', // for 'pages' mode
                  allLabel: 'All',
              }"
          >

              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'displayName'">
                    <span>
                        <a @click="viewUser(props.row.uid)" title="View"><strong>{{ props.row.displayName }}</strong></a>
                    </span>
                  </span>
                  <span v-else>
                      {{props.formattedRow[props.column.field]}}
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import UsersApi from "@/services/admin/usersApi";
import {mapGetters} from 'vuex';

export default {
  name: 'SettingsUsers',
  data() {
    return {
      title: 'User Management',
      api: new UsersApi(),
      tableSearchTerm: '',
      columns: [
        {
          label: 'Name',
          field: 'displayName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Role',
          field: 'customClaims.role',
        }
      ],
      userList: [],
      loading: false
    }
  },
  metaInfo: {
    title: 'All Platform Users'
  },

  components: {
  },

  methods: {
    viewUser: function(ref) {
      let self = this;
      self.$router.push({ name: 'SettingsEditUser', params: { ref: ref } })
    },

    getUsers: async function() {
      let self = this;
      let result = await self.api.getUsers();
      self.userList = result.data;
    }
  },

  async created() {
    let self = this;
    await self.getUsers();
  },

  computed: {
    ...mapGetters([
        'getCurrentWebsite'
    ])
  },

  watch: {
    'getCurrentWebsite': async function () {
      let self = this;
      await self.getUsers();
    }
  }
}
</script>
