<template>
  <section class="page-body dashboard">
    <div class="page-title-bar">
      <h1>Dashboard</h1>
    </div>
    <ul class="form-tabs">
      <li 
        v-for="(tab, tabIndex) in formTabs" 
        :key="tabIndex" 
        @click="currentTab = tab"
        :class="{ 'active' : currentTab === tab}"
        >
        <h2>{{tab}}</h2>
      </li>
    </ul>

    <div class="body-flex" v-if="currentTab === 'Flights' && !loadingDashboardData">
      <div class="body-left four">
        <div class="content-panel no-title">
          <div class="panel-title">
            <h4>Bookings</h4>
          </div>
          <div class="panel-body analytics">
            <div class="core-kpis">
              <div class="kpi">
                <p class="label">Today</p>
                <p class="value">{{ flightData.bookings.today }}</p>
              </div>

              <div class="kpi">
                <p class="label">Yesterday</p>
                <p class="value">{{ flightData.bookings.yesterday }}</p>
              </div>

              <div class="kpi">
                <p class="label">SDLW <i class="fal fa-info-circle" v-tooltip="'Same day last week'"></i></p>
                <p class="value">{{ flightData.bookings.sdlw }}</p>
              </div>
            </div>

            <div class="line-chart">
              <div class="label">Bookings Last 7 Days</div>
              <div class="chart">
                <line-chart :chartdata="flightData.bookings.chartData" :options="chartOptions" :styles="'height: 100px'"></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-center four">
        <div class="content-panel no-title">
          <div class="panel-title">
            <h4>Availability</h4>
          </div>
          <div class="panel-body analytics">
            <div class="core-kpis">
              <div class="kpi">
                <p class="label">Today</p>
                <p class="value">{{ flightData.availability.today }}</p>
              </div>

              <div class="kpi">
                <p class="label">Yesterday</p>
                <p class="value">{{ flightData.availability.yesterday }}</p>
              </div>

              <div class="kpi">
                <p class="label">SDLW <i class="fal fa-info-circle" v-tooltip="'Same day last week'"></i></p>
                <p class="value">{{ flightData.availability.sdlw }}</p>
              </div>
            </div>

            <div class="line-chart">
              <div class="label">Availability Last 7 Days</div>
              <div class="chart">
                <line-chart :chartdata="flightData.availability.chartData" :options="chartOptions" :styles="'height: 100px'"></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-right four">
        <div class="content-panel no-title">
          <div class="panel-title">
            <h4>Search</h4>
          </div>
          <div class="panel-body analytics">
            <div class="core-kpis">
              <div class="kpi">
                <p class="label">Today</p>
                <p class="value">{{ flightData.searches.today }}</p>
              </div>

              <div class="kpi">
                <p class="label">Yesterday</p>
                <p class="value">{{ flightData.searches.yesterday }}</p>
              </div>

              <div class="kpi">
                <p class="label">SDLW <i class="fal fa-info-circle" v-tooltip="'Same day last week'"></i></p>
                <p class="value">{{ flightData.searches.sdlw }}</p>
              </div>
            </div>

            <div class="line-chart">
              <div class="label">Searches Last 7 Days</div>
              <div class="chart">
                <line-chart :chartdata="flightData.searches.chartData" :options="chartOptions" :styles="'height: 100px'"></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="body-full">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Top Searched Flight Routes</h3>
          </div>
          <div class="panel-body">
            <div class="data-table">
              <vue-good-table
                v-if="flightRouteColumns.length"
                :columns="flightRouteColumns"
                :rows="flightRoutesSearch"
                styleClass="vgt-table bookings-table"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'count', type: 'desc'}
                }"

                :pagination-options="{
                    enabled: false
                }"
              >
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body-flex" v-if="currentTab === 'Hotels' && !loadingDashboardData">
      Coming Soon
    </div>

    <div class="body-flex" v-if="loadingDashboardData">
      <div class="body-left">
        <i class="fal fa-spinner-third fa-spin"></i> Loading
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from '@/components/charts/LineChart'
import BookingsApi from "@/services/admin/bookingsApi";

export default {
  name: 'Dashboard',
  data() {
    return {
      bookingsApi: new BookingsApi(),
      currentTab: 'Flights',
      flightRoutesSearch: [],
      flightRouteColumns: [
        {
          label: 'From',
          field: 'origin',
        },
        {
          label: 'To',
          field: 'destination',
        },
        {
          label: 'Search Count',
          field: 'count',
          type: 'number',
        }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
      },
      flightData: {
        bookings: {
          today: 0,
          yesterday: 0,
          sdlw: 0,
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'Bookings',
                backgroundColor: '#00af75',
                borderColor: '#01d48e',
                data: [],
                fill: false
              }
            ]
          },
        },
        availability: {
          today: 0,
          yesterday: 0,
          sdlw: 0,
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'Availability',
                backgroundColor: '#00af75',
                borderColor: '#01d48e',
                data: [],
                fill: false
              }
            ]
          },
        },
        searches: {
          today: 0,
          yesterday: 0,
          sdlw: 0,
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'Searches',
                backgroundColor: '#00af75',
                borderColor: '#01d48e',
                data: [],
                fill: false
              }
            ]
          },
        },
      },
      loadingDashboardData: false,
      axes: {
        display: false
      }
    }
  },
  components: {
    LineChart
  },

  async mounted() {
    let self = this;
    
    await self.getDashboardData();
  },

  methods: {
    getDashboardData: async function() {
      let self = this;

      self.loadingDashboardData = true;
      let today = await self.bookingsApi.getFlightBookingCountsToday();
      let historical = await self.bookingsApi.getFlightBookingCountsHistorical();
      let topSearches = await self.bookingsApi.getFlightTopSearches();
      let lastSevenDays = await self.bookingsApi.getFlightLastSevenDaysStats();

      self.flightRoutesSearch = topSearches.data;
      
      self.flightData.bookings.chartData.labels = Object.keys(lastSevenDays.data.bookingComplete);
      self.flightData.bookings.chartData.datasets[0].data = Object.values(lastSevenDays.data.bookingComplete);

      self.flightData.availability.chartData.labels = Object.keys(lastSevenDays.data.availability);
      self.flightData.availability.chartData.datasets[0].data = Object.values(lastSevenDays.data.availability);

      self.flightData.searches.chartData.labels = Object.keys(lastSevenDays.data.search);
      self.flightData.searches.chartData.datasets[0].data = Object.values(lastSevenDays.data.search);

      self.flightData.bookings.today = today.data.bookingComplete;
      self.flightData.availability.today = today.data.availability;
      self.flightData.searches.today = today.data.search;

      self.flightData.bookings.yesterday = historical.data.yesterday.bookingComplete;
      self.flightData.availability.yesterday = historical.data.yesterday.availability;
      self.flightData.searches.yesterday = historical.data.yesterday.search;

      self.flightData.bookings.sdlw = historical.data.lwsd.bookingComplete;
      self.flightData.availability.sdlw = historical.data.lwsd.availability;
      self.flightData.searches.sdlw = historical.data.lwsd.search;

      self.loadingDashboardData = false;
    }
  },

  computed: {
    ...mapGetters ([ 
      'getHasWebsite',
      'db'
    ]),

    formTabs: function() {
      let self = this;
      if(self.getHasWebsite) {
        return ['Flights', 'Hotels']
        // return ['Flights', 'Hotels', 'Website']
      } else {
        return ['Flights', 'Hotels']
      }
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.getDashboardData();
    }
  }
}
</script>
