import Vue from 'vue'
import App from './App.vue'
import { store } from './store/store.js'
import router from './router'
import GlobalNav from './components/GlobalNav'
import ProfileBar from './components/ProfileBar'
import VueGoodTablePlugin from 'vue-good-table';
import CheckboxToggle from './components/CheckboxToggle.vue';
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {auth} from "@/firebaseConfig";
import SiteApi from "@/services/siteApi";


extend('required', {
  ...required,
  message: 'This field is required'
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

import './assets/css/stylesheet.css'
import 'vue-select/dist/vue-select.css';
import 'vue-good-table/dist/vue-good-table.css'


Vue.component('GlobalNav', GlobalNav);
Vue.component('ProfileBar', ProfileBar);
Vue.component('CheckboxToggle', CheckboxToggle);
Vue.component('v-select', vSelect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VModal, { scrollable: true, dialog: true } );
Vue.use(VueGoodTablePlugin);
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(VueMeta);

Vue.config.productionTip = false;
let app = null;
let api = new SiteApi();
auth.onAuthStateChanged(async (user) => {
  if (user) {
    await store.dispatch("setCurrentUser", await user.getIdTokenResult());
    let site = (await api.getSite()).data;
    await store.dispatch('setCurrentWebsiteData', site);
    window.TravelPlatformAdmin = { site };
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }


});