<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }} 
        <span v-if="hotelRef && hotel.data && hotel.data.label">- {{ hotel.data.label }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="updateHotel(hotelRef ? hotelRef : hotel.ref)">Save Hotel</button>
              
        <router-link :to="{ name: 'Hotels' }" title="All Hotels" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>
    
    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="hotelForm">
          <div class="hotel-form">

            <div class="form-group">
              <label for="hotelName">Hotel Name<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  id="hotelName"
                  v-model="hotel.data"
                  @search="searchDestinationLocation"
                  :options="destinationResults"
                  :placeholder="'Enter a Hotel Name'"
                  label="label"
                  :filterable="false"
                  >
                  <template slot="no-options">
                      Enter a Hotel Name...
                  </template>
                  <template slot="option" slot-scope="option">
                      <div class="autocomplete-result">
                          <i class="fal fa-city" v-if="option.type === 'city'"></i>
                          <i class="fal fa-plane" v-if="option.type === 'airport'"></i>
                          <i class="fal fa-hotel" v-if="option.type === 'hotel'"></i>
                          {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                      <div class="autocomplete-result selected">
                          <i class="fal fa-city" v-if="option.type === 'city'"></i>
                          <i class="fal fa-plane" v-if="option.type === 'airport'"></i>
                          <i class="fal fa-hotel" v-if="option.type === 'hotel'"></i>
                          {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                      </div>
                  </template>
                </v-select>
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="hotelRef">Ref<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="hotelRef" v-model="hotel.ref" :disabled="hotelRef" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="hotelSnippet">Snippet</label>
              <textarea type="text" id="hotelSnippet" v-model="hotel.snippet"></textarea>
            </div>

            <div class="form-group">
              <label for="hotelLowPrice">Low Price</label>
              <input type="number" v-model="hotel.lowPrice" id="hotelLowPrice" />
            </div>
            
            <div class="form-group">
              <label for="hotelPageContent">Hotel Content</label>
              <wysiwyg-editor 
                id="hotelPageContent"
                v-model="hotel.pageContent" 
                class="html-editor"
                :api-key="tinyApi" 
                toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                :init="{}">
              </wysiwyg-editor>
            </div>

            <div class="link-bar" v-if="hotelRef">
              <button class="btn btn-text btn-delete" @click="deleteHotel()" title="Delete this Hotel">
                <i class="fal fa-trash"></i>
                Delete Hotel
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="'websites/' + getCurrentWebsite + '/hotels/' + hotel.ref" :oldImgUrl="hotel.thumbImg"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Header Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getHeaderImgUrl" :parentContext="'websites/' + getCurrentWebsite + '/hotels/' + hotel.ref" :oldImgUrl="hotel.headerImg"></file-uploader>
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'
import HotelsApi from "@/services/admin/hotelsApi";

export default {
  name: 'Hotel',
  data() {
    return {
      hotelsApi: null,
      hotelRef: this.$route.params.ref,
      destinationResults: [],
      hotel: {
        data: null
      },
      tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
      loading: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.hotelRef) {
        return 'Edit Hotel'
      } else {
        return 'Add a Hotel'
      }
    },

    ...mapGetters ([
      'db',
      'getCurrentWebsite'
    ])
  },

  components: {
    'FileUploader': FileUploader,
    'wysiwyg-editor': Editor,
  },

  async mounted() {
    let self = this;
    if(self.hotelRef) {
      self.getHotel();
    }
  },

  created() {
    let self = this;
    self.hotelsApi = new HotelsApi();
  },

  methods: {
    getHotel: async function() {
      let self = this;
      self.loading = true;
      let hotel = await self.db.collection('hotels').doc(self.hotelRef).get();
      self.hotel = hotel.data();
      self.loading = false;
    },

    updateHotel: function(ref) {
      let self = this;
      
      self.$refs.hotelForm.validate().then(success => {
        if(!self.hotel.data.state) {
          self.hotel.data.state = ''
        }
        if(success) {
          if(self.hotelRef) {
            self.db.collection('hotels').doc(ref).update(self.hotel)
            .then(function() {
              self.$notify({
                group: 'saved',
                title: 'Destination Saved',
                type: 'success'
              });
            })
            .catch(function(error) {
              self.$notify({
                group: 'saved',
                title: 'Error Saving Destination',
                type: 'error'
              });
            });
          } else {
            self.db.collection('hotels').doc(ref).set(self.hotel)
            .then(function() {
              self.$notify({
                group: 'saved',
                title: 'Hotel Successfully Created',
                type: 'success'
              });
              self.$router.push({ path: '/hotels/' + ref });
            })
            .catch(function(error) {
              console.log(error);
              self.$notify({
                group: 'saved',
                title: 'Error Creating Hotel',
                type: 'error'
              });
            });
          }
          
        };
      });
    },

    deleteHotel: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this hotel?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => { 
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fal fa-spinner-third fa-spin"></i>';
              
              self.db.collection('hotels').doc(self.hotelRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Hotel Deleted',
                    type: 'error'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Hotels'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting Hotel - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getThumbImgUrl (url) {
      let self = this;
      self.hotel.thumbImg = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.hotel.headerImg = url
    },

    searchDestinationLocation: function(search, loading) {
      let self = this;
      if (self.timeout) clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {

          if(search != '') {
              loading(true);
              self.searchForDestinationUsingApi(search)
                .then(function (results) {
                  results = results.getHotelAutoSuggestV2.results.result;
                  self.destinationResults = [];
                  if (results.cities) {
                    self.destinationResults = self.destinationResults.concat(results.cities.map(item => {
                      return {rank: item.rank, label: item.city, value: item.cityid_ppn, state: item.state_code, country: item.country_code, type: item.type};
                    }));
                  }
                  if (results.airports) {
                    self.destinationResults = self.destinationResults.concat(results.airports.map(item => {
                      return {rank: item.rank, label: item.airport, value: item.iata, state: item.state_code, country: item.country_code, type: item.type}
                    }));
                  }
                  if (results.hotels) {
                    self.destinationResults = self.destinationResults.concat(results.hotels.map(item => {
                      return {rank: item.rank, label: item.hotel_name, value: item.hotelid_ppn, state: item.address.state_code, country: item.address.country_code, type: item.type}
                    }));
                  }
                  loading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
    }, 500);
    },

    searchForDestinationUsingApi: function(search) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.hotelsApi.suggest(search)
            .then(results => { resolve(results.data); })
            .catch(ex => { reject(ex); });
        });
    },

    resetRegion: function() {
      let self = this;
      self.destination.region = ''
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Hotels' })
    },

    'hotel.data': function() {
      let self = this;

      let hotel = self.hotel.data.label.replace(/\s+/g, '-').toLowerCase();

      self.hotel.ref = hotel;
    }
  }

  
}
</script>
