<template>
  <section class="profile-bar">
    <div class="menu">
      <button class="btn btn-text" @click="toggleMainNav">
        <i class="fa fa-bars"></i>
      </button>
    </div>

    <div class="agency-selector" v-if="isSuperUser">
      <v-select 
        :options="agencyList" 
        :reduce="item => item.id" 
        label="id" 
        :clearable="false"
        @input="setCurrentWebsite"
        v-model="selectedWebsite">
      </v-select>
    </div>

    <div class="profile" v-click-outside="hideProfileMenu">
      <div class="selector" @click="showProfileMenu = !showProfileMenu">
        <div class="avatar">
          <i class="fal fa-user"></i>
        </div>
        <div class="text">
          {{ getCurrentUser.claims.name }}
          <i class="fal fa-angle-down"></i>
        </div>

        <div class="profile-menu" v-if="showProfileMenu">
          <ul>
            <li>
              <router-link :to="{ name: 'Profile' }" title="Profile">
                <i class="fal fa-user"></i>
                Profile
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Logout' }" title="Logout">
                <i class="fal fa-sign-out"></i>
                Logout
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { fs } from '@/firebaseConfig';
import { mapGetters } from 'vuex';
import SiteApi from "@/services/siteApi";

export default {
  name: 'ProfileBar',
  data() {
    return {
      api: new SiteApi(),
      showProfileMenu: false,
      agencyList: [],
      selectedWebsite: ''
    }
  },

  methods: {
    toggleMainNav: function() {
      let self = this;
      self.$store.dispatch('toggleMainNav', !self.showFullNav);
    },

    hideProfileMenu: function() {
      let self = this;
      self.showProfileMenu = false;
    },

    getAgencies: async function() {
      let self = this;
      let agencies = await fs.collection('websites').get();
      self.agencyList = agencies.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },

    setCurrentWebsite: async function() {
      let self = this;
      let site = (await self.api.getSite(self.selectedWebsite)).data;
      window.TravelPlatformAdmin = { site };
      await self.$store.dispatch('setCurrentWebsite', self.selectedWebsite);
      await self.$store.dispatch('setCurrentWebsiteData', site);
    },
  },

  async mounted() {
    let self = this;
    self.selectedWebsite = self.getCurrentWebsite;
    await self.getAgencies();
  },

  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
          }
          };
          document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  },

  computed: {
    ...mapGetters ({
      showFullNav: 'getShowFullNav',
      getCurrentUser: 'getCurrentUser',
      getCurrentWebsite: 'getCurrentWebsite'
    }),

    isSuperUser: function() {
      let self = this;
      if(self.getCurrentUser.claims.role === 'su') {
        return true;
      } else { return false }
    }
  }
}
</script>