<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }} 
        <span v-if="destinationRef && destination.data && destination.data.label">- {{ destination.data.label }}</span>
      </h1>

      <div class="btn-set">
        <button class="btn" @click="updateDestination(destinationRef ? destinationRef : destination.ref)">Save Destination</button>
              
        <router-link :to="{ name: 'Destinations' }" title="All Destinations" tag="button" class="btn btn-sec">
          <i class="fal fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>
    
    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="destinationForm">
          <div class="destination-form">

            <div class="form-group">
              <label for="destinationName">Name<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  id="destinationName"
                  v-model="destination.data"
                  @search="searchDestinationLocation"
                  @input="setupDestination"
                  :options="destinationResults"
                  :placeholder="'Enter a Destination'"
                  label="label"
                  :filterable="false"
                  >
                  <template slot="no-options">
                      Enter a Destination...
                  </template>
                  <template slot="option" slot-scope="option">
                      <div class="autocomplete-result">
                          <i class="fal fa-city" v-if="option.type === 'city'"></i>
                          <i class="fal fa-plane" v-if="option.type === 'airport'"></i>
                          <i class="fal fa-hotel" v-if="option.type === 'hotel'"></i>
                          {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                      <div class="autocomplete-result selected">
                          <i class="fal fa-city" v-if="option.type === 'city'"></i>
                          <i class="fal fa-plane" v-if="option.type === 'airport'"></i>
                          <i class="fal fa-hotel" v-if="option.type === 'hotel'"></i>
                          {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                      </div>
                  </template>
                </v-select>
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="destinationRef">Ref<span class="req">*</span></label>
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" id="destinationRef" v-model="destination.ref" :disabled="destinationRef" />
                <span class="invalid">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="form-group">
              <label for="destinationSnippet">Snippet</label>
              <textarea type="text" id="destinationSnippet" v-model="destination.snippet"></textarea>
            </div>

            <div class="form-group">
              <label for="destinationLowPrice">Low Price</label>
              <input type="number" v-model="destination.lowPrice" id="destinationLowPrice" />
            </div>
            
            <div class="form-group" v-if="getHasWebsite">
              <label for="destinationPageContent">Page Content</label>
              <wysiwyg-editor 
                id="destinationPageContent"
                v-model="destination.pageContent" 
                class="html-editor"
                :api-key="tinyApi" 
                toolbar="undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl" 
                plugins="paste importcss searchreplace autolink code visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars" 
                :init="{}">
              </wysiwyg-editor>
            </div>

            <div class="form-group" v-if="getHasWebsite">
              <label for="featuredHotels">Featured Hotels</label>
              <v-select :reduce="hotel => (hotel.ref ? hotel.ref : hotel.data.label)"
                v-model="destination.hotels"
                :options="hotelList"
                multiple
                label="ref"
                value="ref"
                id="featuredHotels"
                name="Hotels">
              </v-select>
            </div>

            <div class="form-group" v-if="destination.airport || destinationAirports.length > 0">
              <label for="destinationName">Destination Airport</label>
              <v-select
                id="destinationAirport"
                v-model="destination.airport"
                :options="destinationAirports"
                :placeholder="'Enter an Airport'"
                label="airport"
                :filterable="false"
                >
                <template slot="no-options">
                    Enter an Airport...
                </template>
                <template slot="option" slot-scope="option">
                    <div class="autocomplete-result">
                        <i class="fal fa-plane"></i>
                        {{ option.airport }}
                         ({{option.iata}})
                    </div>
                </template>
              </v-select>
            </div>

            <div class="form-group" v-if="getHasWebsite">
              <label for="destinationName">Flight Offers</label>
              <v-select
                id="destinationName"
                v-model="destination.flights"
                @search="searchDestinationLocation"
                :options="airportResults"
                :placeholder="'Enter an Airport'"
                label="label"
                multiple
                :filterable="false"
                >
                <template slot="no-options">
                    Enter an Airport...
                </template>
                <template slot="option" slot-scope="option">
                    <div class="autocomplete-result">
                        <i class="fal fa-plane"></i>
                        {{ option.label + ( option.state ? `, ${option.state}` : '' ) + (option.country ? `, ${option.country}` : '') }}
                    </div>
                </template>
              </v-select>
            </div>

            <div class="link-bar" v-if="destinationRef">
              <button class="btn btn-text btn-delete" @click="deleteDestination()" title="Delete this Destination">
                <i class="fal fa-trash"></i>
                Delete Destination
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <div class="content-panel">
          <div class="panel-title">
            <h3>Thumb Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getThumbImgUrl" :parentContext="'websites/' + getCurrentWebsite + '/destinations/' + destination.ref" :oldImgUrl="destination.thumbImg"></file-uploader>
          </div>
        </div>

        <div class="content-panel" v-if="getHasWebsite">
          <div class="panel-title">
            <h3>Header Image</h3>
          </div>
          <div class="panel-body">
            <file-uploader v-on:downloadURL="getHeaderImgUrl" :parentContext="'websites/' + getCurrentWebsite + '/destinations/' + destination.ref" :oldImgUrl="destination.headerImg"></file-uploader>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Feature this Destination</h3>
          </div>
          <div class="panel-body">
            <div class="form-group checkbox-group">
              <label for="featuredDestinationBooking">
                <input type="checkbox" id="featuredDestinationBooking" v-model="destination.featureOnBookingPlatform" />
                Feature on the Booking Platform
              </label>
            </div>

            <div class="form-group checkbox-group" v-if="getHasWebsite">
              <label for="featuredDestinationWebsite">
                <input type="checkbox" id="featuredDestinationWebsite" v-model="destination.featureOnWebsitePlatform" />
                Feature on the Website Platform
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import FileUploader from '@/components/FileUploader'
import { mapGetters } from 'vuex'
import HotelsApi from "@/services/admin/hotelsApi";
import SiteApi from "@/services/siteApi";

export default {
  name: 'Destination',
  data() {
    return {
      hotelsApi: null,
      siteApi: null,
      destinationRef: this.$route.params.ref,
      destinationResults: [],
      airportResults: [],
      hotelList: [],
      destination: {
        data: null
      },
      destinationAirports: [],
      tinyApi: 'cyudstyo31xxl9o7an4ahj9q1dfquhlaulkfzljzjs6tsdea',
      loading: false,
      showRegions: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    title: function() {
      let self = this;

      if(self.destinationRef) {
        return 'Edit Destination'
      } else {
        return 'Add a Destination'
      }
    },

    ...mapGetters ([
      'db',
      'getCurrentWebsite',
      'getHasWebsite'
    ])
  },

  components: {
    'FileUploader': FileUploader,
    'wysiwyg-editor': Editor,
  },

  async mounted() {
    let self = this;
    await self.getHotels();
    if(self.destinationRef) {
      self.getDestination();
    }
    
  },

  created() {
    let self = this;
    self.hotelsApi = new HotelsApi();
    self.siteApi = new SiteApi();
  },

  methods: {
    getDestination: async function() {
      let self = this;
      self.loading = true;
      let destination = await self.db.collection('destinations').doc(self.destinationRef).get();
      self.destination = destination.data();
      self.loading = false;
    },

    updateDestination: async function(ref) {
      let self = this;
      
      let success = await self.$refs.destinationForm.validate();

      if(!self.destination.data.state) {
        self.destination.data.state = '';
      }
      if(success) {
        if(self.destinationRef) {
          try {
            await self.db.collection('destinations').doc(ref).update(self.destination);
            await self.siteApi.clearCache(self.getCurrentWebsite, 'destination', self.destination.ref);
            self.$notify({
              group: 'saved',
              title: 'Destination Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.$notify({
              group: 'saved',
              title: 'Error Saving Destination',
              type: 'error'
            });
          }
        }
        else {
          try {
            await self.db.collection('destinations').doc(ref).set(self.destination)
            self.$notify({
              group: 'saved',
              title: 'Destination Successfully Created',
              type: 'success'
            });
            await self.$router.push({ path: '/destinations/' + ref });
          }
          catch (error) {
            console.log(error);
            self.$notify({
              group: 'saved',
              title: 'Error Creating Destination',
              type: 'error'
            });
          }
        }
      }
    },

    deleteDestination: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this destination?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => { 
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fal fa-spinner-third fa-spin"></i>';
              
              self.db.collection('destinations').doc(self.destinationRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Destination Deleted',
                    type: 'error'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'Destinations'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting Destination - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    getHotels: async function() {
      let self = this;
      let hotels = await self.db.collection('hotels').get();
      self.hotelList = hotels.docs.map(doc => doc.data());
    },

    getThumbImgUrl (url) {
      let self = this;
      self.destination.thumbImg = url
    },

    getHeaderImgUrl (url) {
      let self = this;
      self.destination.headerImg = url
    },

    searchDestinationLocation: function(search, loading) {
      let self = this;
      if (self.timeout) clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {

          if(search != '') {
              loading(true);
              self.searchForDestinationUsingApi(search)
                .then(function (results) {
                  results = results.getHotelAutoSuggestV2.results.result;
                  self.destinationResults = [];
                  if (results.cities) {
                    self.destinationResults = self.destinationResults.concat(results.cities.map(item => {
                      return {rank: item.rank, label: item.city, nearbyAirports: item.nearby_airports_json, value: item.cityid_ppn, state: item.state_code, country: item.country_code, type: item.type};
                    }));
                  }
                  if (results.airports) {
                    self.destinationResults = self.destinationResults.concat(results.airports.map(item => {
                      return {rank: item.rank, label: item.airport, value: item.iata, state: item.state_code, country: item.country_code, type: item.type}
                    }));

                    self.airportResults = results.airports.map(item => {
                      return {rank: item.rank, label: item.airport, value: item.iata, state: item.state_code, country: item.country_code, type: item.type}
                    });
                  }
                  if (results.hotels) {
                    self.destinationResults = self.destinationResults.concat(results.hotels.map(item => {
                      return {rank: item.rank, label: item.hotel_name, value: item.hotelid_ppn, state: item.address.state_code, country: item.address.country_code, type: item.type}
                    }));
                  }
                  loading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
    }, 500);
    },

    searchForDestinationUsingApi: function(search) {
        let self = this;
        return new Promise((resolve, reject) => {
            self.hotelsApi.suggest(search)
            .then(results => { resolve(results.data); })
            .catch(ex => { reject(ex); });
        });
    },

    setupDestination: function() {
      let self = this;

      let destination = self.destination.data.label.replace(/\s+/g, '-').toLowerCase();
      let stateCode = self.destination.data.state ? self.destination.data.state.toLowerCase() : '';
      let countryCode = self.destination.data.country ? self.destination.data.country.toLowerCase() : '';

      self.destination.ref = destination + '-' + ( stateCode ? stateCode : countryCode );
      
      if(self.destinationAirports) {
        self.destinationAirports = JSON.parse(self.destination.data.nearbyAirports.replace(/\&quot;/gi, '\"'));
      }
    },

    resetRegion: function() {
      let self = this;
      self.destination.region = ''
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Destinations' })
    }
  }

  
}
</script>
